import(/* webpackMode: "eager" */ "/usr/tmp/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Noto_Sans_KR\",\"arguments\":[{\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-noto-sans\"}],\"variableName\":\"notoSans\"}");
;
import(/* webpackMode: "eager" */ "/usr/tmp/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Nanum_Gothic\",\"arguments\":[{\"display\":\"swap\",\"weight\":[\"400\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--font-nanum-gothic\"}],\"variableName\":\"nanumGothic\"}");
;
import(/* webpackMode: "eager" */ "/usr/tmp/node_modules/normalize.css/normalize.css");
;
import(/* webpackMode: "eager" */ "/usr/tmp/node_modules/reset-css/reset.css");
;
import(/* webpackMode: "eager" */ "/usr/tmp/src/app/assets/favicon.ico");
;
import(/* webpackMode: "eager", webpackExports: ["RenderState"] */ "/usr/tmp/src/providers/render-state.tsx");
;
import(/* webpackMode: "eager" */ "/usr/tmp/src/styles/global.scss");
