import { Environment } from './constant.interface';

export const global: Window =
  typeof window === 'undefined' ? ({} as Window) : window;

/**
 * GoogleOauthClientId
 */
export const googleOauthClientId =
  process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID || '';

/**
 * ApiUrl
 */
export const apiUrl = process.env.NEXT_PUBLIC_API_URL || '';

export const isCSR = typeof window !== 'undefined';
export const isSSR = typeof window === 'undefined';

/**
 * Current executing environment
 */
export const runtimeEnv =
  process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT || 'development';

/**
 * Current executing environment
 */
export const env: Environment = ((rEnv: string | undefined): Environment => {
  switch (rEnv) {
    case 'production': {
      return Environment.Production;
    }
    case 'staging': {
      return Environment.Staging;
    }
    case 'development':
    default: {
      return Environment.Development;
    }
  }
})(runtimeEnv);

/**
 * Whether it is the development environment
 * → If staging or development envrinoment, it will return `true`
 */
export const isDevelopment = env !== Environment.Production;

/**
 * CDN URL
 * → It uses th website and images and etc...
 */
export const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL;

/**
 * Sentry DSN
 */
export const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

/**
 * Whether it is a local-development environment
 */
export const isLocalDev = process.env.NODE_ENV === 'development';

/**
 * Whether it is the storybook environment
 */
export const isStorybookRuntimeEnv = process.env.STORYBOOK === 'true';
