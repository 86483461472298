/* eslint-disable import/prefer-default-export */
/**
 * 실행 환경
 */
export enum Environment {
  /**
   * 개발
   */
  Development = 'dev',
  /**
   * 스테이징
   */
  Staging = 'stg',
  /**
   * 상용
   */
  Production = 'prd',
}
