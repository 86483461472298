'use client';

import { isCSR } from '@/constant';
import { RenderStateProvider, Store } from 'react-render-state-hook';

export const store = Store.createStore({
  debug: process.env.NODE_ENV === 'development' && isCSR,
});

export function RenderState({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return <RenderStateProvider store={store}>{children}</RenderStateProvider>;
}
